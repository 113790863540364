import { BsThreeDots } from "react-icons/bs";
import { MdModeEdit } from "react-icons/md";
import TablePaginator from "src/admin/layout/TablePaginator";
import StatusChangePopup from "src/admin/pages/orders/StatusChangePopup";
import { usePopup } from "src/components/popup/ModalContext";

function Orders() {
  const { openPopup } = usePopup();

  const orderStatuses = [
    {
      id: 1,
      name: "Feldolgozás alatt",
    },
    {
      id: 2,
      name: "Kézbseítve",
    },
  ];

  const paymentStatuses = [
    {
      id: 1,
      name: "Pending",
    },
    {
      id: 2,
      name: "Fizetve",
    },
  ];

  return (
    <div className="w-full px-10 py-2">
      <table className="w-full divide-y divide-table-border border border-table-border">
        <thead className="bg-bg-secondary text-color-on-bg-secondary">
          <tr>
            <th>Rendelés azonosítója</th>
            <th>Rendelés ideje</th>
            <th>Kapcsolat</th>
            <th>Rendelési státusz</th>
            <th>Fizetési státusz</th>
            <th>Beszállítói státusz</th>
            <th>Ár</th>
            <th>Csomag súlya</th>
            <th></th>
          </tr>
        </thead>
        <tbody className="divide-y divide-table-border">
          <tr>
            <td>2k3jh4g52k3j4</td>
            <td>2024. 10. 04 07:33</td>
            <td>
              <div className="flex flex-col">
                <span>szandasoma@gmail.com</span>
                <span>+36302386803</span>
              </div>
            </td>
            <td>
              <div
                className="flex flex-row gap-2 items-center p-1 px-2 hover:bg-bg-primary2 rounded hover:shadow cursor-pointer w-fit"
                onClick={() =>
                  openPopup(
                    "Rendelési státusz módosítása",
                    <StatusChangePopup
                      order_id="2k3jh4g52k3j4"
                      save={(a) => console.log("set: ", a)}
                      statusTitle="Rendelési állapot"
                      status={1}
                      options={orderStatuses}
                    />
                  )
                }
              >
                <span>Kézbesítve</span>
                <MdModeEdit />
              </div>
            </td>
            <td>Kézbesítve</td>
            <td>Kézbesítve</td>
            <td>56 234 Ft</td>
            <td>33455 g</td>
            <td>
              <BsThreeDots />
            </td>
          </tr>
        </tbody>
      </table>
      <TablePaginator />
    </div>
  );
}
export default Orders;
