import { useEffect, useState } from "react";
import { FaRegStar, FaStar } from "react-icons/fa";
import { MdDelete, MdEdit, MdOutlineAdd } from "react-icons/md";
import { AlertType, useAlert } from "src/components/alert/AlertProvider";
import Button from "src/components/form-components/Button";
import Loading from "src/components/loading/Loading";
import { usePopup } from "src/components/popup/ModalContext";
import { ShippingData } from "src/data/Profile";
import ShippingDataForm from "src/pages/profile/ShippingDataForm";
import apiService from "src/shared/apiService/ApiService";

function SavedShippingsList() {
  const { openPopup, closePopup } = usePopup();
  const { showAlert } = useAlert();
  const [shippingAddresses, setShippingAddresses] = useState<ShippingData[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    initShippingAddresses();
  }, []);

  function initShippingAddresses() {
    apiService
      .get<ShippingData[]>("/shipping-address")
      .then((response: ShippingData[]) => {
        setShippingAddresses(
          response.sort((a) => (a.primary_shipping_address ? -1 : 1))
        );
        setLoading(false);
      });
  }

  function openEdit(shippingData: ShippingData) {
    openPopup(
      "Szállítási adat szerkesztése",
      <ShippingDataForm
        currentShippingData={shippingData}
        saveCallback={() => initShippingAddresses()}
      />
    );
  }

  function openDeleteConfirm(shippingData: ShippingData) {
    openPopup(
      "Szállítási adat törlése",
      <div className="flex flex-col gap-1">
        <span>Biztosan szeretnéd törölni a mentett szállítási adatot?</span>

        <Button onClick={() => deleteItem(shippingData)}>Igen</Button>
        <Button color="text-secondary" onClick={closePopup}>
          Mégse
        </Button>
      </div>
    );
  }

  function deleteItem(shippingData: ShippingData) {
    apiService.delete("/shipping-address", { id: shippingData.id }).then(() => {
      showAlert({
        title: "Sikeres törlés",
        description: "Szállítási cím törlése sikeres",
        type: AlertType.SUCCESS,
      });
    });
    setShippingAddresses(
      shippingAddresses.filter((data) => data.id !== shippingData.id)
    );
    closePopup();
    initShippingAddresses();
  }

  function openNewShippingForm() {
    openPopup(
      "Új szállítási adat hozzáadása",
      <ShippingDataForm
        newData
        shippingData={shippingAddresses}
        saveCallback={() => initShippingAddresses()}
      />
    );
  }

  function setPrimary(shippingData: ShippingData) {
    apiService
      .put("/shipping-address", {
        ...shippingData,
        primary_shipping_address: true,
      })
      .then(() => {
        setShippingAddresses(
          shippingAddresses
            .map((address) =>
              shippingData.id !== address.id
                ? {
                    ...address,
                    primary_shipping_address: false,
                  }
                : { ...shippingData, primary_shipping_address: true }
            )
            .sort((a) => (a.primary_shipping_address ? -1 : 1))
        );
        showAlert({
          title: "Sikeres módosítás",
          description: "Elsődleges szállítási cím sikeresen beállítva",
          type: AlertType.SUCCESS,
        });
      });
  }

  return (
    <div className="w-full h-fit pb-1 flex flex-col gap-2">
      <h1 className="text-lg font-bold">Mentett szállítási adatok kezelése</h1>
      <small className="flex flex-row items-center">
        <FaStar className="text-color-primary" /> - Elsődleges szállítási cím
      </small>
      <div
        className="bg-bg-primary2 p-2 w-full self-center h-fit flex justify-center items-center gap-2 rounded shadow text-text-secondary cursor-pointer hover:scale-105 transition-all duration-100"
        onClick={openNewShippingForm}
      >
        <MdOutlineAdd className="text-lg" />
        Új hozzáadása
      </div>
      {loading && (
        <div className="w-full flex justify-center p-2">
          <Loading />
        </div>
      )}
      {shippingAddresses.map((shippingData, index) => (
        <div
          key={index}
          className="flex w-full bg-bg-primary2 shadow rounded px-2 py-1 items-center"
        >
          <div className="text-2xl mr-5 ms-3">
            {shippingData.primary_shipping_address ? (
              <FaStar className="text-color-primary cursor-pointer" />
            ) : (
              <FaRegStar
                className="hover:text-color-primary text-text-secondary cursor-pointer"
                onClick={() => setPrimary(shippingData)}
              />
            )}
          </div>
          <div className="flex flex-col gap-1 flex-1">
            <span>
              {shippingData.zip_code} {shippingData.city},{" "}
              {shippingData.street_address}
            </span>
            <span>
              {shippingData.floor_number}. emelet, {shippingData.door_number}.
              ajtó
            </span>
          </div>
          <div className="flex flex-row gap-2 text-2xl items-center pe-2 text-text-secondary">
            <div
              className="hover:text-color-primary p-1 cursor-pointer"
              onClick={() => {
                openEdit(shippingData);
              }}
            >
              <MdEdit />
            </div>
            <div
              className="hover:text-color-primary p-1 cursor-pointer"
              onClick={() => openDeleteConfirm(shippingData)}
            >
              <MdDelete />
            </div>
          </div>
        </div>
      ))}
      <div></div>
    </div>
  );
}
export default SavedShippingsList;
