import { useEffect, useState } from "react";
import Loading from "src/components/loading/Loading";
import { OrderOverviewData } from "src/data/Order";
import OrderItem from "src/pages/profile/OrderItem";
import apiService from "src/shared/apiService/ApiService";

function OrdersList() {
  const [orders, setOrders] = useState<OrderOverviewData[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    apiService
      .get<OrderOverviewData[]>("/order")
      .then((response: OrderOverviewData[]) => {
        setOrders(response);
        setLoading(false);
      });
  }, []);

  return (
    <div className="w-full rounded">
      <h1 className="text-lg font-bold mb-2">Korábbi rendelések</h1>
      <div className="flex flex-col gap-3">
        {orders.map((order, index) => (
          <OrderItem order={order} key={index} />
        ))}
        {loading && (
          <div className="w-full flex justify-center p-10">
            <Loading />
          </div>
        )}
        {loading === false && orders.length === 0 && (
          <span>Nincsenek rendelések</span>
        )}
      </div>
    </div>
  );
}
export default OrdersList;
