import { t } from "i18next";
import { useEffect, useState } from "react";
import { MdDelete, MdOutlineAdd, MdOutlineRemove } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useAuth } from "src/components/auth/AuthContext";
import { useCart } from "src/components/cart/CartProvider";
import Button from "src/components/form-components/Button";
import Checkbox from "src/components/form-components/Checkbox";
import InputField from "src/components/form-components/InputField";
import SelectField from "src/components/form-components/SelectField";
import TextArea from "src/components/form-components/TextArea";
import Loading from "src/components/loading/Loading";
import { Order, OrderPostResponse } from "src/data/Order";
import { PaymentType } from "src/data/PaymentType";
import { ProfileData, ShippingData } from "src/data/Profile";
import { ShippingType } from "src/data/ShippingType";
import apiService from "src/shared/apiService/ApiService";

function Checkout() {
  const {
    cart,
    cartTotalPrice,
    clearCart,
    cartLoaded,
    addToCart,
    decreaseQuantity,
    removeFromCart,
  } = useCart();
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();

  const [shippingTypes, setShippingTypes] = useState<ShippingType[]>();
  const [paymentTypes, setPaymentTypes] = useState<PaymentType[]>();

  const [orderForm, setOrderForm] = useState<Partial<Order>>({
    same_shipment_and_billing: 0,
  });
  const [orderFormErrors, setOrderFormErrors] = useState<{
    [key: string]: string;
  }>();
  const [shippingAddresses, setShippingAddresses] = useState<ShippingData[]>(
    []
  );
  const [shippingAddressOptions, setShippingAddressOptions] = useState<
    { id: number; name: string }[]
  >([]);

  useEffect(() => {
    getShippingTypes();
    getPaymentTypes();
    if (isLoggedIn) {
      apiService.get<ProfileData>("/profile").then((response: ProfileData) => {
        setOrderForm((prev) => ({
          ...prev,
          email: response.email,
          phone_number: response.phone_number,
          shipment_last_name: response.last_name,
          shipment_first_name: response.first_name,
        }));
      });
      apiService
        .get<ShippingData[]>("/shipping-address")
        .then((response: ShippingData[]) => {
          setShippingAddresses(
            response.sort((a) => (a.primary_shipping_address ? -1 : 1))
          );
          setShippingAddressOptions(
            response.map((item) => ({
              id: item.id,
              name: `${item.zip_code} ${item.city}, ${item.street_address}`,
            }))
          );
          if (response.length > 0) {
            setOrderForm((prev) => ({
              ...prev,
              shipment_address_zip_code: response[0].zip_code,
              shipment_address_city: response[0].city,
              shipment_address_street_address: response[0].street_address,
              shipment_address_floor_number: response[0].floor_number,
              shipment_address_door_number: response[0].door_number,
            }));
          }
        });
    }
  }, []);

  function changeShippingData(shippingDataId: number) {
    const shippingData = shippingAddresses.find(
      (data) => data.id === shippingDataId
    );
    if (shippingData) {
      setOrderForm((prev) => ({
        ...prev,
        shipment_address_zip_code: shippingData.zip_code,
        shipment_address_city: shippingData.city,
        shipment_address_street_address: shippingData.street_address,
        shipment_address_floor_number: shippingData.floor_number,
        shipment_address_door_number: shippingData.door_number,
      }));
    }
  }

  useEffect(() => {
    if (cartLoaded && cart.length === 0) {
      navigate("/");
    }
    setOrderForm((prev) => ({
      ...prev,
      products: cart.map((cartItem) => ({
        product_id: cartItem.product_id,
        quantity: cartItem.quantity,
      })),
    }));
  }, [cart]);

  function getShippingTypes() {
    apiService
      .get<ShippingType[]>("/shipping/types")
      .then((response: ShippingType[]) => {
        setShippingTypes(response);
      });
  }

  function getPaymentTypes() {
    apiService
      .get<PaymentType[]>("/payment/types")
      .then((response: PaymentType[]) => {
        setPaymentTypes(response);
      });
  }

  function changeSameShipmentAndBilling(state: boolean) {
    setOrderForm((prev) => ({
      ...prev,
      same_shipment_and_billing: state ? 1 : 0,
    }));

    if (state) {
      setOrderForm((prev) => ({
        ...prev,
        billing_first_name: orderForm?.shipment_first_name,
        billing_last_name: orderForm?.shipment_last_name,
        billing_address_zip_code: orderForm?.shipment_address_zip_code,
        billing_address_city: orderForm?.shipment_address_city,
        billing_address_street_address:
          orderForm?.shipment_address_street_address,
        billing_address_floor_number: orderForm?.shipment_address_floor_number,
        billing_address_door_number: orderForm?.shipment_address_door_number,
      }));
    }
  }

  function getShipmentPrice() {
    return (
      shippingTypes?.find(
        (shipmentType) => shipmentType.id === orderForm?.shipment_type_id
      )?.price ?? 0
    );
  }

  function getPaymentPrice() {
    return (
      paymentTypes?.find(
        (paymentType) => paymentType.id === orderForm?.payment_type_id
      )?.price ?? 0
    );
  }

  function getFinalPrice() {
    return cartTotalPrice + getShipmentPrice() + getPaymentPrice();
  }

  function submitOrder() {
    setOrderFormErrors(undefined);
    apiService
      .post<OrderPostResponse>("/order", orderForm)
      .then((response: OrderPostResponse) => {
        clearCart();
        navigate("/orderOverview", {
          state: {
            order_data: response.message.order_data[0],
          },
        });
      })
      .catch((error) => {
        setOrderFormErrors(error.message);
      });
  }

  return (
    <div className="p-2 flex flex-col items-center w-full">
      <div className="flex flex-row gap-10 justify-center max-w-[80%] w-full">
        <div className="w-fit h-fit flex flex-col gap-2">
          <span className="font-bold text-lg mt-1">Személyes adatok</span>
          <div className="flex flex-col w-fit gap-5 bg-bg-primary2 shadow rounded p-2">
            <div className="flex flex-row gap-1">
              <InputField
                placeholder="E-mail cím"
                value={orderForm?.email}
                change={(value) =>
                  setOrderForm((prev) => ({ ...prev, email: value }))
                }
                errorKey={orderFormErrors?.email}
              ></InputField>
              <InputField
                placeholder="Telefon"
                value={orderForm?.phone_number}
                change={(value) =>
                  setOrderForm((prev) => ({ ...prev, phone_number: value }))
                }
                errorKey={orderFormErrors?.phone_number}
              ></InputField>
            </div>
          </div>
          <span className="font-bold text-lg mt-5">Szállítási adatok</span>
          <div className="flex flex-col w-fit gap-2 bg-bg-primary2 shadow rounded p-2">
            {isLoggedIn && shippingAddressOptions.length > 0 && (
              <div className="mb-5">
                <span>Válaszz mentett szálítási adatok közül</span>
                <SelectField
                  options={shippingAddressOptions}
                  change={(item) => {
                    changeShippingData(item);
                  }}
                ></SelectField>
              </div>
            )}

            <div className="flex flex-row gap-1">
              <InputField
                placeholder="Vezetéknév"
                value={orderForm?.shipment_last_name}
                change={(value) =>
                  setOrderForm((prev) => ({
                    ...prev,
                    shipment_last_name: value,
                  }))
                }
                errorKey={orderFormErrors?.shipment_last_name}
              ></InputField>
              <InputField
                placeholder="Keresznév"
                value={orderForm?.shipment_first_name}
                change={(value) =>
                  setOrderForm((prev) => ({
                    ...prev,
                    shipment_first_name: value,
                  }))
                }
                errorKey={orderFormErrors?.shipment_first_name}
              ></InputField>
            </div>
            <div className="flex flex-row gap-1">
              <InputField
                placeholder="Irányítószám"
                type="number"
                value={orderForm?.shipment_address_zip_code}
                change={(value) =>
                  setOrderForm((prev) => ({
                    ...prev,
                    shipment_address_zip_code: value,
                  }))
                }
                errorKey={orderFormErrors?.shipment_address_zip_code}
              ></InputField>
              <InputField
                placeholder="Település"
                value={orderForm?.shipment_address_city}
                change={(value) =>
                  setOrderForm((prev) => ({
                    ...prev,
                    shipment_address_city: value,
                  }))
                }
                errorKey={orderFormErrors?.shipment_address_city}
              ></InputField>
            </div>
            <InputField
              placeholder="Utca, házszám"
              value={orderForm?.shipment_address_street_address}
              change={(value) =>
                setOrderForm((prev) => ({
                  ...prev,
                  shipment_address_street_address: value,
                }))
              }
              errorKey={orderFormErrors?.shipment_address_street_address}
            ></InputField>
            <div className="flex flex-row gap-1">
              <InputField
                placeholder="Emelet"
                type="number"
                value={orderForm?.shipment_address_floor_number}
                change={(value) =>
                  setOrderForm((prev) => ({
                    ...prev,
                    shipment_address_floor_number: value,
                  }))
                }
                errorKey={orderFormErrors?.shipment_address_floor_number}
              ></InputField>
              <InputField
                placeholder="Ajtó"
                type="text"
                value={orderForm?.shipment_address_door_number}
                change={(value) =>
                  setOrderForm((prev) => ({
                    ...prev,
                    shipment_address_door_number: value,
                  }))
                }
                errorKey={orderFormErrors?.shipment_address_door_number}
              ></InputField>
            </div>
          </div>
          <span className="font-bold text-lg mt-5">Számlázási adatok</span>
          <div className="flex flex-col w-fit gap-2 bg-bg-primary2 shadow rounded p-2">
            <Checkbox
              checked={orderForm?.same_shipment_and_billing === 1}
              checkCallback={(state: boolean) =>
                changeSameShipmentAndBilling(state)
              }
            >
              Megegyezik a szállítási adatokkal.
            </Checkbox>
            <div className="flex flex-row gap-1">
              <InputField
                placeholder="Vezetéknév"
                value={
                  orderForm?.same_shipment_and_billing === 1
                    ? orderForm?.shipment_last_name
                    : orderForm?.billing_last_name
                }
                change={(value) =>
                  setOrderForm((prev) => ({
                    ...prev,
                    billing_last_name: value,
                  }))
                }
                errorKey={orderFormErrors?.billing_last_name}
              ></InputField>
              <InputField
                placeholder="Keresznév"
                value={
                  orderForm?.same_shipment_and_billing === 1
                    ? orderForm?.shipment_first_name
                    : orderForm?.billing_first_name
                }
                change={(value) =>
                  setOrderForm((prev) => ({
                    ...prev,
                    billing_first_name: value,
                  }))
                }
                errorKey={orderFormErrors?.billing_first_name}
              ></InputField>
            </div>
            <div className="flex flex-row gap-1">
              <InputField
                placeholder="Irányítószám"
                type="number"
                value={
                  orderForm?.same_shipment_and_billing === 1
                    ? orderForm?.shipment_address_zip_code
                    : orderForm?.billing_address_zip_code
                }
                change={(value) =>
                  setOrderForm((prev) => ({
                    ...prev,
                    billing_address_zip_code: value,
                  }))
                }
                errorKey={orderFormErrors?.billing_address_zip_code}
              ></InputField>
              <InputField
                placeholder="Település"
                value={
                  orderForm?.same_shipment_and_billing === 1
                    ? orderForm?.shipment_address_city
                    : orderForm?.billing_address_city
                }
                change={(value) =>
                  setOrderForm((prev) => ({
                    ...prev,
                    billing_address_city: value,
                  }))
                }
                errorKey={orderFormErrors?.billing_address_city}
              ></InputField>
            </div>
            <InputField
              placeholder="Utca, házszám"
              value={
                orderForm?.same_shipment_and_billing === 1
                  ? orderForm?.shipment_address_street_address
                  : orderForm?.billing_address_street_address
              }
              change={(value) =>
                setOrderForm((prev) => ({
                  ...prev,
                  billing_address_street_address: value,
                }))
              }
              errorKey={orderFormErrors?.billing_address_street_address}
            ></InputField>
            <div className="flex flex-row gap-1">
              <InputField
                placeholder="Emelet"
                type="number"
                value={
                  orderForm?.same_shipment_and_billing === 1
                    ? orderForm?.shipment_address_floor_number
                    : orderForm?.billing_address_floor_number
                }
                change={(value) =>
                  setOrderForm((prev) => ({
                    ...prev,
                    billing_address_floor_number: value,
                  }))
                }
                errorKey={orderFormErrors?.billing_address_floor_number}
              ></InputField>
              <InputField
                placeholder="Ajtó"
                type="text"
                value={
                  orderForm?.same_shipment_and_billing === 1
                    ? orderForm?.shipment_address_door_number
                    : orderForm?.billing_address_door_number
                }
                change={(value) =>
                  setOrderForm((prev) => ({
                    ...prev,
                    billing_address_door_number: value,
                  }))
                }
                errorKey={orderFormErrors?.billing_address_door_number}
              ></InputField>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-10 flex-1 justify-center h-fit w-full">
          <div className="flex flex-col gap-2">
            <span className="font-bold text-lg mt-2">Szállítási forma </span>
            {orderFormErrors?.shipment_type_id && (
              <div className="text-color-error text-sm mb-2">
                {t(`error.${orderFormErrors?.shipment_type_id}`)}
              </div>
            )}
            {!shippingTypes && (
              <div className="w-full flex justify-center p-10">
                <Loading></Loading>
              </div>
            )}
            {shippingTypes &&
              shippingTypes.map((shippingType, index) => (
                <div className="bg-bg-primary2 p-2 rounded shadow">
                  <Checkbox
                    key={index}
                    description={shippingType.description}
                    checked={orderForm?.shipment_type_id === shippingType.id}
                    checkCallback={() =>
                      setOrderForm((prev) => ({
                        ...prev,
                        shipment_type_id: shippingType.id,
                      }))
                    }
                    price={shippingType.price}
                  >
                    {shippingType.name}
                  </Checkbox>
                </div>
              ))}
          </div>

          <div className="flex flex-col w-full gap-2 p-2">
            <span className="font-bold text-lg">Fizetési forma</span>
            {orderFormErrors?.payment_type_id && (
              <div className="text-color-error text-sm mb-2">
                {t(`error.${orderFormErrors?.payment_type_id}`)}
              </div>
            )}
            {!paymentTypes && (
              <div className="w-full flex justify-center p-10">
                <Loading></Loading>
              </div>
            )}
            {paymentTypes &&
              paymentTypes.map((paymentType, index) => (
                <div className="shadow bg-bg-primary2 rounded p-2">
                  <Checkbox
                    key={index}
                    description={paymentType.description}
                    checked={orderForm?.payment_type_id === paymentType.id}
                    checkCallback={() =>
                      setOrderForm((prev) => ({
                        ...prev,
                        payment_type_id: paymentType.id,
                      }))
                    }
                    price={paymentType.price}
                  >
                    {paymentType.name}
                  </Checkbox>
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className="flex flex-col max-w-[80%] w-[80%] gap-2 bg-bg-primary2 shadow rounded p-2 mt-10">
        <span>Megjegyzés</span>
        <TextArea
          placeholder="Megjegyzés"
          value={orderForm.comment}
          change={(value) =>
            setOrderForm((prev) => ({ ...prev, comment: value }))
          }
          errorKey={orderFormErrors?.comment}
        ></TextArea>
      </div>

      <div className="flex flex-col w-full gap-2  max-w-[80%] mt-10 p-2 mb-2">
        <span className="font-bold text-lg mt-1">Rendelés összegzése</span>
        Termékek
        {cart.map((cartItem, index) => (
          <div
            key={index}
            className="bg-bg-primary2 rounded shadow p-2 flex flex-row gap-2 w-full items-center"
          >
            <div className="rounded overflow-hidden shadow w-fit">
              <img src="https://placehold.co/80x80" alt="" />
            </div>
            <div className="flex-1">
              <h1 className="font-bold text-lg">{cartItem.name}</h1>
              <span className="font-thin">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Distinctio, quasi.
              </span>
            </div>
            <div className="flex flex-col items-end gap-2">
              <strong className="text-color-primary">
                {cartItem.unit_price * cartItem.quantity} Ft
              </strong>
              <div className="flex flex-row gap-2 items-center">
                <MdOutlineRemove
                  className="text-2xl bg-bg-primary rounded shadow cursor-pointer"
                  onClick={() => decreaseQuantity(cartItem)}
                />
                <span className="px-2">{cartItem.quantity}</span>
                <MdOutlineAdd
                  className="text-2xl bg-bg-primary rounded shadow cursor-pointer"
                  onClick={() => addToCart(cartItem)}
                />
              </div>
            </div>
            <div
              className="h-full p-5 flex items-center text-2xl text-text-secondary hover:text-color-primary cursor-pointer"
              onClick={() => removeFromCart(cartItem.product_id)}
            >
              <MdDelete />
            </div>
          </div>
        ))}
        <div className="flex flex-row gap-5">
          <div className="flex flex-col bg-bg-primary2 rounded shadow p-2 w-fit gap-2">
            <div className="flex flex-row gap-20 justify-between">
              <span>Összeg:</span>
              <span>{cartTotalPrice} Ft</span>
            </div>
            <div className="flex flex-row gap-20 justify-between">
              <span>Szállítási költség:</span>
              <span>{getShipmentPrice()} Ft</span>
            </div>
            <div className="flex flex-row gap-20 justify-between">
              <span>Utánvét költsége:</span>
              <span>{getPaymentPrice()} Ft</span>
            </div>
            <div className="flex flex-row gap-20 justify-between">
              <span>Csomag súlya:</span>
              <span>{0} g</span>
            </div>
            <div className="h-px w-full bg-table-border"></div>
            <strong className="text-lg my-2 flex flex-row gap-20 justify-between">
              <span>Végösszeg:</span>
              <span>{getFinalPrice()} Ft</span>
            </strong>
          </div>
        </div>
        <div className="bg-bg-primary2 rounded shadow p-2 gap-2 flex flex-col flex-1 justify-end h-fit">
          <Checkbox checked>Elfogadom ezt meg azt</Checkbox>
          <Checkbox checked>
            Hírlevél checkbox, vagy valami ami még kell
          </Checkbox>
          <Button
            className="w-full text-xl justify-self-end"
            onClick={() => submitOrder()}
          >
            Rendelés leadása
          </Button>
        </div>
      </div>
    </div>
  );
}
export default Checkout;
