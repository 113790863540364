export interface SelectOptionType {
  id: number;
  name: string;
}

interface SelectFieldProps {
  value?: number;
  placeholder?: string;
  change?: (e: number) => void;
  error?: string;
  errorKey?: string;
  required?: boolean;
  options: SelectOptionType[];
}

function SelectField({
  value,
  placeholder,
  change,
  error,
  errorKey,
  required = false,
  options,
}: SelectFieldProps) {
  return (
    <div>
      <div
        className={`bg-bg-primary rounded-sm shadow text-white px-2 py-1 border-[1px] ${
          error || errorKey
            ? "border-color-error animate-[input-error_0.2s_linear]"
            : "border-text-secondary"
        }`}
      >
        <select
          className="bg-transparent w-full h-full border-0 outline-0"
          onChange={(e) => change && change(+e.target.value)}
          value={value}
        >
          {options.map((option, index) => (
            <option key={index} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
        {/* {error && <span className="text-color-error text-sm">{error}</span>}
        {errorKey && (
          <span className="text-color-error text-sm">
          {t(`error.${errorKey}`)}
          </span>
          )} */}
      </div>
    </div>
  );
}

export default SelectField;
