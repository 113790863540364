import { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AdminLayout from "src/admin/layout/AdminLayout";
import Orders from "src/admin/pages/orders/Orders";
import { useAuth } from "src/components/auth/AuthContext";
import { PermissionLevel } from "src/components/auth/Permissions";

function AdminPage() {
  const { hasPermission } = useAuth();

  useEffect(() => {
    document.title = "nadovi webadmin";
  }, []);

  return (
    <Routes>
      <Route path="" element={<AdminLayout />}>
        <Route
          path="orders"
          element={
            hasPermission(PermissionLevel.ADMIN_ORDERS_PANEL) ? (
              <Orders />
            ) : (
              <Navigate to={"/admin"} />
            )
          }
        ></Route>
        {/* <Route path="discounts" element={<Discounts />}></Route>
        <Route path="settings" element={<Settings />}></Route> */}
        <Route path="*" element={<Navigate to={""} />}></Route>
      </Route>
    </Routes>
  );
}

export default AdminPage;
