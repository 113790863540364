import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { AlertType, useAlert } from "src/components/alert/AlertProvider";
import { useAuth } from "src/components/auth/AuthContext";
import { CartItem, CartResponse } from "src/data/Cart";

import apiService from "src/shared/apiService/ApiService";

interface CartContextType {
  cart: CartItem[];
  cartLoaded: boolean;
  cartTotalPrice: number;
  addToCart: (item: CartItem) => void;
  decreaseQuantity: (item: CartItem) => void;
  removeFromCart: (id: string) => void;
  clearCart: () => void;
}

const CartContext = createContext<CartContextType | undefined>(undefined);

export const CartProvider = (props: { children: ReactNode }) => {
  const [cart, setCart] = useState<CartItem[]>([]);
  const [cartLoaded, setCartLoaded] = useState<boolean>(false);
  const [cartTotalPrice, setCartTotalPrice] = useState<number>(0);
  const { isLoggedIn } = useAuth();
  const { showAlert } = useAlert();

  useEffect(() => {
    if (isLoggedIn) {
      apiService.get<CartResponse>("/cart").then((response: CartResponse) => {
        setCartTotalPrice(response.total_price);
        if (response.products) {
          const mergedCarts = mergeCarts(response.products);
          setCart(mergedCarts);
          localStorage.removeItem("local-cart");
          apiAddToCart(mergedCarts);
        } else {
          setCart(JSON.parse(localStorage.getItem("local-cart") || "[]"));
          if (cart.length > 0) {
            apiAddToCart(cart);
          }
        }
        setCartLoaded(true);
      });
    } else {
      setCart(JSON.parse(localStorage.getItem("local-cart") || "[]"));
    }
  }, [isLoggedIn]);

  useEffect(() => {
    setCartTotalPrice(
      cart.reduce((acc, item) => acc + item.quantity * item.unit_price, 0)
    );

    if (isLoggedIn == undefined) return;

    if (isLoggedIn) {
      localStorage.setItem("cart", JSON.stringify(cart));
      localStorage.removeItem("local-cart");
    } else {
      localStorage.setItem("local-cart", JSON.stringify(cart));
    }
  }, [cart]);

  function mergeCarts(cartToMerge: CartItem[]) {
    const localCart = localStorage.getItem("local-cart");
    if (localCart) {
      return [...cartToMerge, ...JSON.parse(localCart)].reduce((acc, item) => {
        let existingCartItem = acc.find(
          (existingIem: CartItem) => existingIem.product_id === item.product_id
        );
        if (existingCartItem) {
          existingCartItem.quantity += item.quantity;
        } else {
          acc.push({ ...item });
        }
        return acc;
      }, []);
    } else {
      return cartToMerge;
    }
  }

  function apiAddToCart(cartItems: CartItem[]) {
    if (!isLoggedIn) return;
    if (cartItems.length === 0) return;
    apiService
      .put("/cart", { products: cartItems })
      .then(() => {})
      .catch(() => {});
  }

  function apiRemoveFromCart(itemId: string) {
    if (!isLoggedIn) return;
    apiService
      .delete("/cart", { product_id: itemId })
      .then(() => {
        setCart((prevCart) =>
          prevCart.filter((item) => item.product_id !== itemId)
        );
      })
      .catch(() => {});
  }

  const addToCart = (item: CartItem, decrease: boolean = false) => {
    showAlert({
      title: "Kosár frissítve",
      description: "A kosár tartalma frissült",
      type: AlertType.SUCCESS,
    });
    setCart((prevCart) => {
      const existingItem = prevCart.find(
        (cartItem) => cartItem.product_id === item.product_id
      );
      if (existingItem) {
        apiAddToCart([
          {
            ...item,
            quantity: decrease
              ? existingItem.quantity - 1
              : existingItem.quantity + 1,
          },
        ]);
        return prevCart.map((cartItem) =>
          cartItem.product_id === item.product_id
            ? {
                ...cartItem,
                quantity: decrease
                  ? cartItem.quantity - 1
                  : cartItem.quantity + 1,
              }
            : cartItem
        );
      }
      apiAddToCart([{ ...item, quantity: 1 }]);
      return [...prevCart, { ...item, quantity: 1 }];
    });
  };

  const removeFromCart = (id: string) => {
    apiRemoveFromCart(id);
    setCart((prevCart) => prevCart.filter((item) => item.product_id !== id));
  };

  const decreaseQuantity = (cartItem: CartItem) => {
    if (cartItem.quantity === 1) {
      removeFromCart(cartItem.product_id);
    } else {
      addToCart(cartItem, true);
    }
  };

  const clearCart = () => {
    localStorage.removeItem("local-cart");
    localStorage.removeItem("cart");
    setCart([]);
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        cartLoaded,
        cartTotalPrice,
        clearCart,
        decreaseQuantity,
        addToCart,
        removeFromCart,
      }}
    >
      {props.children}
    </CartContext.Provider>
  );
};

export const useCart = (): CartContextType => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};
