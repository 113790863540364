import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { PermissionLevel } from "src/components/auth/Permissions";
import apiService from "src/shared/apiService/ApiService";

interface AuthContextType {
  isLoggedIn: boolean;
  loading: boolean;
  loggedInUserId: number | undefined;
  login: () => void;
  logout: () => void;
  hasPermission: (permissionToCheck: PermissionLevel) => boolean;
  setUserPermissions: (permissions: PermissionLevel[]) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [loggedInUserId, setLoggedInUserId] = useState<number>();
  const [permissions, setPermissions] = useState<
    PermissionLevel[] | undefined
  >();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    checkAuth();
  }, []);

  function checkAuth() {
    apiService
      .get("/login/verify")
      .then((response: any) => {
        if (response.message.permission) {
          setPermissions(response.message.permission);
        }
        const userId = localStorage.getItem("id");
        if (userId) {
          setLoggedInUserId(+userId);
        }
        login();
      })
      .catch(() => {
        logout();
      });
  }

  const login = () => {
    setIsLoggedIn(true);
    setLoading(false);
  };

  const logout = () => {
    setIsLoggedIn(false);
    setLoading(false);
    setPermissions(undefined);
  };

  const setUserPermissions = (permissions: PermissionLevel[]) => {
    setPermissions(permissions);
  };

  const hasPermission = (permissionToCheck: PermissionLevel) => {
    if (!permissions) return false;
    return permissions.includes(permissionToCheck);
  };

  return (
    <AuthContext.Provider
      value={{
        setUserPermissions,
        hasPermission,
        loggedInUserId,
        isLoggedIn,
        loading,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
