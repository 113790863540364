import { PaymentType } from "src/data/PaymentType";
import { Product } from "src/data/Product";
import { ShippingType } from "src/data/ShippingType";

export interface Order {
  products: { product_id: string; quantity: number }[];

  shipment_type_id: number;
  payment_type_id: number;
  same_shipment_and_billing: number;
  shipment_first_name: string;
  shipment_last_name: string;
  shipment_address_zip_code: string;
  shipment_address_city: string;
  shipment_address_street_address: string;
  shipment_address_floor_number: number;
  shipment_address_door_number: string;
  email: string;
  phone_number: string;
  billing_first_name: string;
  billing_last_name: string;
  billing_address_zip_code: string;
  billing_address_city: string;
  billing_address_street_address: string;
  billing_address_floor_number: number;
  billing_address_door_number: string;
  comment: string;
}

export interface OrderPostResponse {
  message: {
    order_data: OrderOverviewData[];
  };
}

export interface OrderOverviewData {
  id: string;
  products: Product[];
  order_status: OrderStatus;

  shipment_type: ShippingType;
  payment_type: PaymentType;

  shipment_first_name: string;
  shipment_last_name: string;
  shipment_address_city: string;
  shipment_address_zip_code: string;
  shipment_address_street_address: string;
  shipment_address_floor_number: number;
  shipment_address_door_number: string;

  billing_first_name: string;
  billing_last_name: string;
  billing_address_zip_code: string;
  billing_address_city: string;
  billing_address_street_address: string;
  billing_address_floor_number: number;
  billing_address_door_number: string;

  comment: string;
  email: string;
  phone_number: string;

  total_price: number;
  total_weight: number;

  created_at: string;
}

export enum OrderStatus {
  PENDING = "Pending",
  PROCESSING = "Processing",
  SHIPPING = "Shipping",
  COMPLETED = "Completed",
  CANCELLED = "Cancelled",
  REJECTED = "Rejected",
}
