import { FaCheckDouble } from "react-icons/fa";
import { GoPackageDependencies } from "react-icons/go";

function OrderDetails() {
  return (
    <div className="w-full flex justify-center p-10">
      <div className="max-w-[80%] flex flex-col w-full gap-5">
        <h1 className="text-5xl flex flex-row gap-2 text-text-secondary items-center font-thin">
          Rendelés részletei
          <small className="font-bold text-text-primary">(sjhdfsjdhgf)</small>
        </h1>
        <div className="flex flex-row gap-2">
          <span className="text-text-secondary">Rendelés állapota:</span>
          <strong className="flex flex-row gap-2 items-center">
            Feldolgozás alatt <GoPackageDependencies />
          </strong>
        </div>
        <div className="flex flex-row gap-2">
          <span className="text-text-secondary">Fizetés állapota:</span>
          <strong className="flex flex-row gap-2 items-center">
            Fizetve <FaCheckDouble />
          </strong>
        </div>
        <div className="w-full bg-bg-primary2 rounded shadow p-2 flex flex-col gap-1">
          <div className="flex flex-row gap-2">
            <span className="text-text-secondary">Rendelés rögzítrve:</span>
            <strong>2025. január 55.</strong>
          </div>
          <div className="flex flex-row gap-2">
            <span className="text-text-secondary">Rendelés végősszege:</span>
            <strong>2 000 000 Ft</strong>
          </div>
          <div className="flex flex-row gap-2">
            <span className="text-text-secondary">Csomag súlya:</span>
            <strong>2 000 g</strong>
          </div>
        </div>
        <div className="flex flex-row gap-5">
          <div className="flex flex-1 bg-bg-primary2 shadow rounded p-2 flex-col gap-2">
            <h1 className="text-lg font-bold">Szállítás adatai</h1>
            <div className="flex flex-row gap-2">
              <span className="text-text-secondary">Kiszállítás módja:</span>
              <strong>GLS házhozszállítás</strong>
            </div>
            <div className="flex flex-row gap-2">
              <span className="text-text-secondary">Cím:</span>
              <strong>
                6000 Kecskemét, Izsáki út 12, 22. emelet, 566. ajtó
              </strong>
            </div>
            <div className="flex flex-row gap-2">
              <span className="text-text-secondary">Szállítási költség:</span>
              <strong>1500 Ft</strong>
            </div>
          </div>
          <div className="flex flex-1 bg-bg-primary2 shadow rounded p-2 flex-col gap-2">
            <h1 className="text-lg font-bold">Számlázás adatai</h1>
            <div className="flex flex-row gap-2">
              <span className="text-text-secondary">Számlázás adatok:</span>
              <strong>Szanda Soma, +36302386803</strong>
            </div>
            <div className="flex flex-row gap-2">
              <span className="text-text-secondary">Cím:</span>
              <strong>
                6000 Kecskemét, Izsáki út 12, 22. emelet, 566. ajtó
              </strong>
            </div>
          </div>
          <div className="flex flex-1 bg-bg-primary2 shadow rounded p-2 flex-col gap-2">
            <h1 className="text-lg font-bold">Utánvét adatai</h1>
            <div className="flex flex-row gap-2">
              <span className="text-text-secondary">Fizetési mód:</span>
              <strong>Utánvétes fizetés</strong>
            </div>
            <div className="flex flex-row gap-2">
              <span className="text-text-secondary">Utánvét költsége</span>
              <strong>3500 Ft</strong>
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col gap-2">
          <h1 className="text-lg font-bold">Termékek</h1>
          {[1, 2, 3].map((item) => (
            <div className="bg-bg-primary2 shadow rounded p-3">
              <div className="flex flex-row gap-2 items-center">
                <div className="rounded shadow w-fit h-fit">
                  <img src="https://placehold.co/120x120" alt="" />
                </div>
                <div>
                  <h1 className="text-lg font-bold">Termék neve 1 2</h1>
                  <span className="font-thin">
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Iure excepturi, ducimus voluptatem eaque ut aperiam, numquam
                    deleniti, similique voluptate cumque ipsum? Hic, perferendis
                    harum delectus unde nobis omnis eveniet? Assumenda!
                  </span>
                  <div className="flex flex-row gap-2">
                    <span className="text-text-secondary">Mennyiség:</span>
                    <strong>3 darab</strong>
                  </div>
                  <div className="flex flex-row gap-2">
                    <span className="text-text-secondary">Ár:</span>
                    <strong>
                      3000 Ft <small>(1000 Ft / darab)</small>
                    </strong>
                  </div>
                  <div className="flex flex-row gap-2">
                    <span className="text-text-secondary">Súly:</span>
                    <strong>
                      6000 g <small>(2000 g / darab)</small>
                    </strong>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default OrderDetails;
