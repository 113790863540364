import { t } from "i18next";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AlertType, useAlert } from "src/components/alert/AlertProvider";
import { useAuth } from "src/components/auth/AuthContext";
import ForgotPassword from "src/components/auth/forgot-password/ForgotPassword";
import { PermissionLevel } from "src/components/auth/Permissions";
import Button from "src/components/form-components/Button";
import InputField from "src/components/form-components/InputField";
import { usePopup } from "src/components/popup/ModalContext";
import apiService from "src/shared/apiService/ApiService";

interface LoginForm {
  email: string;
  password: string;
}

interface LoginResponse {
  id: number;
  login_token: string;
  permissions: PermissionLevel[];
}

function Login() {
  const { login, setUserPermissions } = useAuth();
  const { openPopup } = usePopup();
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const [loginLoading, setLoginLoading] = useState(false);
  const [loginForm, setLoginForm] = useState<LoginForm>({
    email: "",
    password: "",
  });

  const [loginErrors, setLoginErrors] = useState<Partial<LoginForm>>({});

  function loginSubmit() {
    setLoginErrors({});
    setLoginLoading(true);
    apiService
      .post<LoginResponse>("/login", loginForm)
      .then((response: LoginResponse) => {
        localStorage.setItem("login_token", response.login_token);
        localStorage.setItem("id", response.id.toString());
        setUserPermissions(response.permissions);
        login();
        showAlert({
          title: "Bejelentkezés",
          description: "Sikeres bejelentkezés",
          type: AlertType.SUCCESS,
        });
        setLoginLoading(false);
      })
      .catch((error) => {
        setLoginErrors(error.message);
        setLoginLoading(false);
      });
  }

  return (
    <div className="flex justify-center p-10">
      <div className="h-fit flex flex-col items-center w-96">
        <h1 className="font-bold text-3xl text-gray-700 mb-2">
          {t("login.title")}
        </h1>
        <form className="flex flex-col w-full gap-4">
          <InputField
            required
            placeholder={t("login.email")}
            value={loginForm.email}
            change={(value) =>
              setLoginForm((prev) => ({ ...prev, email: value }))
            }
            errorKey={loginErrors.email}
          ></InputField>
          <InputField
            placeholder={t("login.password")}
            type="password"
            autoComplete="password"
            value={loginForm.password}
            change={(value) =>
              setLoginForm((prev) => ({ ...prev, password: value }))
            }
            errorKey={loginErrors.password}
          ></InputField>
        </form>
        <Button
          className="w-fit m-5"
          onClick={() => loginSubmit()}
          type="button"
          loading={loginLoading}
        >
          {t("login.submit")}
        </Button>
        <div className="flex flex-col gap-2 items-center text-text-secondary">
          <a
            className="underline cursor-pointer"
            onClick={() =>
              openPopup("Elfelejtett jelszó", <ForgotPassword></ForgotPassword>)
            }
          >
            {t("login.forgot_password")}
          </a>
          <a
            className="underline cursor-pointer"
            onClick={() => navigate("/register")}
          >
            {"Regisztráció"}
          </a>
        </div>
      </div>
    </div>
  );
}

export default Login;
