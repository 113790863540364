import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { AlertType, useAlert } from "src/components/alert/AlertProvider";
import { useAuth } from "src/components/auth/AuthContext";
import Button from "src/components/form-components/Button";
import { usePopup } from "src/components/popup/ModalContext";
import { OrderOverviewData, OrderStatus } from "src/data/Order";
import apiService from "src/shared/apiService/ApiService";

interface OrderItemProps {
  order: OrderOverviewData;
}

function OrderItem({ order }: OrderItemProps) {
  const { openPopup, closePopup } = usePopup();
  const { loggedInUserId } = useAuth();
  const { showAlert } = useAlert();

  function openCancelOrder() {
    openPopup(
      "Rendelés visszavonása",
      <div className="w-full flex flex-col gap-2">
        <span>Le szeretnéd mondani a rendelést?</span>
        <span>
          Rendelés azonosítója: <strong>{order.id}</strong>
        </span>

        <Button onClick={() => cancelOrder()}>Rendelés lemondása</Button>
        <Button color="text-secondary">Mégse</Button>
      </div>
    );
  }

  function cancelOrder() {
    apiService
      .delete("/order", { order_id: order.id, user_id: loggedInUserId })
      .then(() => {
        showAlert({
          title: "Sikeres törlés",
          description: "Sikeresen törölted a rendelést",
          type: AlertType.SUCCESS,
        });
        closePopup();
      });
  }

  return (
    <div className="flex flex-col p-2 bg-bg-primary2 shadow rounded gap-2">
      <div className="flex flex-row w-full justify-between">
        <span className="font-bold">Rendelés száma: {order.id}</span>
        <span className="text-sm">2024. január. 22.</span>
      </div>
      <span>
        Rendelés státusza: <strong>{order.order_status}</strong>
      </span>
      <span>
        Végösszeg: <strong>{order.total_price} Ft</strong>
      </span>
      <div className="w-full h-px bg-table-border"></div>
      <div className="flex flex-row justify-between">
        <NavLink
          to={`/orderDetails?order=${order.id}`}
          className="flex flex-row gap-2 items-center hover:bg-bg-primary w-fit px-2 py-1 rounded hover:shadow cursor-pointer"
        >
          Rendelés részletei <MdOutlineKeyboardArrowRight className="text-xl" />
        </NavLink>
        {order.order_status === OrderStatus.PENDING && (
          <Button onClick={() => openCancelOrder()}>
            Rendelés visszavonása
          </Button>
        )}
      </div>
    </div>
  );
}
export default OrderItem;
