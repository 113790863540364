import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCart } from "src/components/cart/CartProvider";
import { OrderOverviewData } from "src/data/Order";

function OrderOverview() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { clearCart } = useCart();

  const [orderOwerviewData, setOrderOwerviewData] =
    useState<OrderOverviewData>();

  useEffect(() => {
    if (!state.order_data) navigate("/");
    clearCart();
    setOrderOwerviewData(state.order_data);
  }, []);

  return (
    <div className="felx w-full items-center flex justify-center py-10 flex-col gap-10">
      <div className="max-w-[80%] flex flex-col text-center">
        <h1 className="text-5xl font-extrabold m-5">Köszönjük a rendelésed!</h1>
        <span>Rendelésed megkaptuk és feldolgozását elkezdtük</span>
        <span className="text-text-secondary">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae
          neque amet sit laudantium eos iusto quod aliquid minima, ab facere
          magnam aut tempore? Iure, rem aut voluptas repellat nisi et aliquam
          esse consequatur ullam quas suscipit ex maxime ut delectus. Eaque sunt
          quas id optio incidunt ea, necessitatibus soluta possimus?
        </span>
      </div>

      <div className="flex flex-row justify-between max-w-[80%] w-full gap-5">
        <div className="flex-1 bg-bg-primary2 rounded shadow p-2 ">
          <h1 className="font-bold text-lg">Szállítási adatok</h1>
          <div>
            {`${orderOwerviewData?.shipment_address_zip_code} ${orderOwerviewData?.shipment_address_city}, ${orderOwerviewData?.shipment_address_street_address}`}
          </div>
          <div>
            {`${orderOwerviewData?.shipment_address_floor_number}. emelet ${orderOwerviewData?.shipment_address_door_number}. ajtó`}
          </div>
        </div>
        <div className="flex-1 bg-bg-primary2 rounded shadow p-2">
          <h1 className="font-bold text-lg">Számlázási adatok</h1>
          <div>
            {`${orderOwerviewData?.billing_address_zip_code} ${orderOwerviewData?.billing_address_city}, ${orderOwerviewData?.billing_address_street_address}`}
          </div>
          <div>
            {`${orderOwerviewData?.billing_address_floor_number}. emelet ${orderOwerviewData?.billing_address_door_number}. ajtó`}
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-between max-w-[80%] w-full gap-5">
        <div className="flex-1 bg-bg-primary2 rounded shadow p-2">
          <h1 className="font-bold text-lg">Kapcsolati adatok</h1>
          <div>{`${orderOwerviewData?.email}`}</div>
          <div>{`${orderOwerviewData?.phone_number}`}</div>
        </div>
        <div className="flex-1 bg-bg-primary2 rounded shadow p-2">
          <h1 className="font-bold text-lg">Rendeléshez tartozó megjegyzés</h1>
          <div>{`${orderOwerviewData?.comment ?? "Nincs"}`}</div>
        </div>
      </div>

      <div className="flex-1 bg-bg-primary2 rounded shadow p-2 max-w-[80%] w-full">
        <h1 className="font-bold text-lg">Rendelés tartalma</h1>
        <div>
          {orderOwerviewData?.products.map((product, index) => (
            <div key={index}>{product.name}</div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default OrderOverview;
