import { useEffect, useState } from "react";
import { AlertType, useAlert } from "src/components/alert/AlertProvider";
import Button from "src/components/form-components/Button";
import InputField from "src/components/form-components/InputField";
import { ProfileData } from "src/data/Profile";
import apiService from "src/shared/apiService/ApiService";

function ProfileForm() {
  const [profileDataForm, setProfileDataForm] = useState<ProfileData>();
  const [profileFormErrors, setProfileFormErrors] = useState<
    Partial<ProfileData> & { floor_number: string }
  >();
  const [profileSaveLoading, setProfileSaveLoading] = useState(false);
  const { showAlert } = useAlert();

  useEffect(() => {
    apiService.get<ProfileData>("/profile").then((response: ProfileData) => {
      setProfileDataForm(response);
    });
  }, []);

  function saveProflie() {
    setProfileSaveLoading(true);
    setProfileFormErrors(undefined);
    apiService
      .put("/profile", profileDataForm)
      .then(() => {
        showAlert({
          title: "Profil frissítve",
          description: "Profil adatai frissültek",
          type: AlertType.SUCCESS,
        });
        setProfileSaveLoading(false);
      })
      .catch((error) => {
        setProfileFormErrors(error.message);
        setProfileSaveLoading(false);
      });
  }

  return (
    <div className="flex flex-col gap-2 w-fit">
      <h1 className="text-lg font-bold">Profil adatok</h1>

      <div className="flex flex-col gap-2 bg-bg-primary2 p-2 rounded  shadow">
        <div className="flex flex-row gap-2">
          <InputField
            placeholder="Vezetkénév"
            value={profileDataForm?.last_name}
            errorKey={profileFormErrors?.last_name}
            change={(value) =>
              setProfileDataForm((prev: any) => ({
                ...prev,
                last_name: value,
              }))
            }
          ></InputField>
          <InputField
            placeholder="Keresztnév"
            value={profileDataForm?.first_name}
            errorKey={profileFormErrors?.first_name}
            change={(value) =>
              setProfileDataForm((prev: any) => ({
                ...prev,
                first_name: value,
              }))
            }
          ></InputField>
        </div>
        <InputField
          placeholder="E-mail cím"
          value={profileDataForm?.email}
          errorKey={profileFormErrors?.email}
          change={(value) =>
            setProfileDataForm((prev: any) => ({
              ...prev,
              email: value,
            }))
          }
        ></InputField>
        <InputField
          placeholder="Telefonszám"
          value={profileDataForm?.phone_number}
          errorKey={profileFormErrors?.phone_number}
          change={(value) =>
            setProfileDataForm((prev: any) => ({
              ...prev,
              phone_number: value,
            }))
          }
        ></InputField>
        <Button loading={profileSaveLoading} onClick={saveProflie}>
          Mentés
        </Button>
      </div>
    </div>
  );
}

export default ProfileForm;
