import { useState } from "react";
import Button from "src/components/form-components/Button";
import SelectField from "src/components/form-components/SelectField";

interface StatusChangePopupProps {
  order_id: string;
  save: (status: any) => void;
  status: number;
  statusTitle: string;
  options: any[];
}

function StatusChangePopup({
  order_id,
  status,
  save,
  statusTitle,
  options,
}: StatusChangePopupProps) {
  const [selectedStatus, setSelectedStatus] = useState(status);

  return (
    <div className="flex flex-col gap-1">
      <span>
        <span>Válaszd ki a rendeléshez tartozó új </span>
        <span className="text-color-primary">{statusTitle}</span>-ot
      </span>
      <span>
        Rendelés azonosítója: <strong>{order_id}</strong>
      </span>
      <SelectField
        options={options}
        placeholder="Állapot kiválasztása"
        change={(e) => setSelectedStatus(e)}
      />
      <Button onClick={() => save(selectedStatus)}>Mentés</Button>
    </div>
  );
}
export default StatusChangePopup;
