import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";

function TablePaginator() {
  return (
    <div className="w-fit border border-table-border mt-1 float-right flex flex-row text-2xl rounded overflow-hidden cursor-pointer items-center title-font">
      <div className="hover:bg-bg-primary2">
        <MdKeyboardDoubleArrowLeft />
      </div>
      <div className="hover:bg-bg-primary2">
        <MdKeyboardArrowLeft />
      </div>
      <div className="text-lg px-2 font-bold text-color-primary">1</div>
      <div className="text-lg px-2 font-bold">2</div>
      <div className="text-lg px-2 font-bold">3</div>
      <div className="text-lg px-2 font-bold">4</div>
      <div className="text-lg px-2 font-bold">...</div>
      <div className="hover:bg-bg-primary2">
        <MdKeyboardArrowRight />
      </div>
      <div className="hover:bg-bg-primary2">
        <MdKeyboardDoubleArrowRight />
      </div>
    </div>
  );
}
export default TablePaginator;
