import { ReactNode } from "react";
import Loading from "src/components/loading/Loading";

export interface ButtonProps {
  children?: ReactNode;
  onClick?: (e: any) => void;
  type?: "button" | "submit" | "reset";
  icon?: ReactNode;
  className?: string;
  badge?: number;
  loading?: boolean;
  color?: string;
}

function Button({
  children,
  type = "button",
  onClick,
  icon,
  className = "",
  loading = false,
  badge,
  color = "color-primary",
}: ButtonProps) {
  return (
    <button
      className={`${className} relative rounded-sm shadow py-1 text-color-on-primary font-bold flex flex-row gap-2 items-center justify-center px-4 transition-colors duration-150 bg-${color} hover:bg-${color}-hover`}
      type={type}
      disabled={loading}
      onClick={(e) => onClick && onClick(e)}
    >
      {badge !== undefined && (
        <small className="absolute w-4 h-4 rounded-full shadow -top-1 -right-1 bg-red-600 text-xs font-medium z-10">
          {badge}
        </small>
      )}
      {loading && (
        <div className="absolute">
          <Loading></Loading>
        </div>
      )}
      <div
        className={`flex flex-row gap-2 items-center ${loading && "opacity-0"}`}
      >
        {icon}
        {children}
      </div>
    </button>
  );
}

export default Button;
