import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { NavLink, Route, Routes } from "react-router-dom";
import OrdersList from "src/pages/profile/OrdersList";
import ProfileForm from "src/pages/profile/ProfileForm";
import SavedShippingsList from "src/pages/profile/SavedShippingsList";

function Profile() {
  const profileMenuItems = [
    {
      link: "",
      name: "Profilom",
    },
    {
      link: "savedShippings",
      name: "Szállítási adatok",
    },
    {
      link: "orders",
      name: "Rendeléseim",
    },
  ];

  return (
    <div className="flex flex-row w-full justify-center h-full">
      <div className="flex flex-row gap-5 max-w-[60%] w-[60%] p-10 h-full justify-center">
        <div className="bg-bg-primary2 w-60 flex flex-col gap-2 p-2 h-fit rounded shadow sticky">
          {profileMenuItems.map((menuItem, index) => (
            <NavLink
              key={index}
              end
              className={({ isActive }) =>
                `hover:bg-bg-primary hover:shadow cursor-pointer px-2 py-1 flex flex-row items-center ` +
                (isActive ? "font-bold text-color-primary" : "")
              }
              to={menuItem.link}
            >
              <span className="flex-1">{menuItem.name}</span>
              <span className="text-lg">
                <MdOutlineKeyboardArrowRight />
              </span>
            </NavLink>
          ))}
        </div>
        <div className="flex-1 h-fit">
          <Routes>
            <Route path="/" element={<ProfileForm />} />
            <Route path="savedShippings" element={<SavedShippingsList />} />
            <Route path="orders" element={<OrdersList />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default Profile;
